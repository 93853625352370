import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchServiceCodes(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/service_code/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchServiceCode(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/service_code/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addServiceCode(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/service_code', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteServiceCode(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/service_code', {params:data})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
